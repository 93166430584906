.title {
  width: 320px;
  color: #545454;
  font-family: Georgia;
  font-size: 35px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 2.1px;
  text-align: center; }
.about {
  height: 586px;
  .container {
    display: flex;
    justify-content: center; }
  &__inner {
    padding-top: 90px;
    display: flex; }
  &__img {
    margin-right: 120px; }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  &__content--text {
    width: 415px;
    margin-top: 25px;
    color: #4c4c4c;
    font-family: "Arial MT";
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; } }
@media only screen and (max-width: 820px) {
  .about {
    &__img {
      display: none; } } }
