.friends {

  .container {
    display: flex;
    height: 833px;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 78px; }
  &__inner {

      background-color: #f6f6f6; }

  &__title {
    width: 400px;
    text-align: center; }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    margin-top: 66px; }

  &__slider {
    display: flex;
    max-width: 1170px;
    justify-content: space-between;
    padding-top: 60px; } }


