.contacts {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;
    margin-bottom: 135px; }
  &__information {
    display: flex;
    justify-content: center;
    margin-top: 60px; }
  &__questions {
    margin-right: 50px; }
  &__visit {
    margin-left: 100px; }
  &__title--item {
    font-size: 30px;
    width: 100%; }
  &__contact--item {
    font-size: 20px;
    margin-top: 20px; }
  &__text {
    margin-top: 20px; } }
