.details {
  color: #545454;
  padding-top: 70px;
  padding-bottom: 100px;
  .container {
    display: flex;
    justify-content: center; }
  &__inner {
    display: flex; }
  &__content {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px; }
  &__breed {
    margin-top: 10px;
    font-family: Georgia;
    font-size: 20px;
    margin-bottom: 20px; }
  &__desc {
    width: 270px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px; }
  &__list {
    margin-top: 25px;
    font-family: Georgia;
    font-size: 20px;
    letter-spacing: 1.02px; }
  &__list--items {
    margin-bottom: 10px; }

  &__img {
    display: flex;
    justify-content: center;
    margin-right: 80px;
    width: 270px;
    background-color: #fafafa;
    img {} } }

