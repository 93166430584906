.help {
  &__inner {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  &__title {
    margin-top: 70px;
    margin-bottom: 80px;
    text-align: center; }
  &__content {
    display: flex;

    flex-wrap: wrap;
    justify-content: center;
    align-items: centet; }


  &__content--item {
    padding-right: 110px;
    margin-bottom: 80px;
    &:nth-child(5) {
      padding-right: 0px; }
    &:nth-child(9) {
      padding-right: 0px; } }
  &__content--img {
    display: block;
    margin-left: auto;
    margin-right: auto; }

  &__content--title {

    color: #545454;
    font-family: Georgia;
    font-size: 20px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 1.2px; } }
.img-wrapper {
  height: 75px; }
