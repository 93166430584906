.donation {
  height: 535px;
  background-color: #f6f6f6;

  .container {
    display: flex;
    justify-content: center; }

  &__inner {
    display: flex;
    padding-top: 70px;
    margin-bottom: 70px; }
  &__img {
    display: block;
    width: 505px;
    height: 261px;
    margin-right: 140px; }
  &__title {
    width: 378px; }
  &__text {
    width: 312px;
    height: 15px;
    color: #545454;
    font-family: Georgia;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.9px;
    margin-top: 35px; }
  &__bank {
    display: flex;
    width: 319px;
    height: 47px;
    border-radius: 9px;
    background-color: #f1cdb3;
    margin-top: 13px;
    align-items: center;
    padding-left: 14px;
    margin-bottom: 50px; }
  &__bank--card {
    display: block;
    width: 30px;
    height: 24px;
    margin-right: 15px; }
  &__bank--number {
    color: #292929;

    font-size: 18px;
    font-weight: 400;
    line-height: 45px;

    letter-spacing: 1.2px; }
  &__desc {
    width: 360px;
    height: 65px;
    color: #b2b2b2;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding-bottom: 100px; } }


@media only screen and (max-width: 980px) {
  .donation {
    &__img {
      display: none; } } }
