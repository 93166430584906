$seconary:  #f1cdb3;


.button {
  width: 205px;
  height: 52px;
  border-radius: 26px;
  background-color:#fddcc4 {}
  color: #292929;
  font-size: 17px;
  font-weight: bold;
  font-family: Georgia;
  letter-spacing: 1.02px;
  &:hover {
    background-color: #f1cdb3; } }


.header {
  max-width: 1920px;
  min-width: 320px;
  overflow: hidden;
  &__inner {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 112px;
    align-items: center; }

  &__content {
    background: url('./header-bg.png') top center;
    height: 925px;
    .container {
      flex-direction: column;
      padding-top: 250px;
      align-items: flex-start; } }

  &__content--title {
    color: #ffffff;
    font-size: 44px;
    line-height: 57px;
    letter-spacing: 2.64px;
    width: 376px; }
  &__content--text {
    color: #cdcdcd;
    font-size: 15px;
    line-height: 24px;
    width: 483px;
    margin-bottom: 45px; }
  &__content--button {
    display: flex;
    justify-content: center;
    align-items: center; } }
.menu__btn-toggle {
  display: none; }

@media only screen and (max-width: 820px) {
  .header {
    &__inner {}

    &__menu--main {
      display: none; }
    &__menu--side {
      display: flex;
      flex-direction: column; } }

  .menu__list-item {
    display: flex;
    height: 30px; }
  .menu__btn-toggle {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    width: 20px;
    height: 15px;
    border: none;
    position: relative;
    opa {}
    &::after {
      content: "";
      width: 20px;
      height: 3px;
      background-color: #545454;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 6px 0 0 #545454, 0 12px 0 0 #545454; } }
  .layer {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 1; }
  .header__side-menu {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0px;
    left: 0px;

    z-index: 11;
    background-color: #fff;
    width: 290px;
    height: 100%;

    .logo__link-img {
      padding-bottom: 20px;
      padding-top: 20px;
      padding-left: 30px; }

    .menu__list-item {
      height: 14px;
      padding-left: 30px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 30px;
      align-items: center;
      cursor: pointer;

      &:nth-child(18) {
          margin-bottom: 30px; } }

    .dash {
      border-top: 1px solid #e1e1e1;
      margin-top: 30px;
      &:nth-child(1) {
        margin-top: 0px; } } } }




@media only screen and (max-width: 360px) {
  .button {
    .menu__btn-toggle {
      padding-right: 10px; } }
  .header {
    height: 625px;

    &__inner {
      min-height: 40px;
      padding-top: 30px; }
    &__content {

      .container {
        padding-top: 80px; } }


    &__content--title {

      font-size: 32px;
      width: 290px; }
    &__content--text {
      width: 280px; } }
  .container {
    padding: 0 25px 0 10px; }

  .header__side-menu {
    top: 0px;
    left: 0px;

    .logo__link-img {
      padding-bottom: 11px;
      padding-top: 11px;
      padding-left: 15px; }
    .menu__list-item {
      height: 14px;
      padding-left: 30px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      align-items: center;
      cursor: pointer;
      &:nth-child(1) {
        margin-top: 0px; } } } }
