.footer {
  background: url('./bg-footer.png') top center;
  height: 362px;
  &__inner {
    display: flex;
    margin-top: 80px; }
  &__title {
    width: 300px;
    height: 78px;
    color: #ffffff;
    font-family: Georgia;
    font-size: 35px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 2.1px;
    margin-bottom: 34px; }
  &__contact--item {
    display: flex;
    align-items: center;
    margin-bottom: 34px; }
  &__text {
    color: #f1cdb3;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 1.2px;
    margin-left: 20px;
    width: 290px; }
  &__visit {
    margin-left: 140px; } }
