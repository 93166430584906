.friends {
  &__slider--item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    width: 270px;
    height: 424px;
    border-radius: 9px; }
  &__slider--img {
    width: 270px;
    height: 270px;
    display: flex;
    align-items: flex-end;
    justify-content: center; }
  &__slider--title {
    width: 74px;
    height: 24px;
    color: #545454;
    font-family: Georgia;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 25px;

    letter-spacing: 1.2px; }
  &__slider--button {
    margin-bottom: 34px;
    border: 3px solid #f1cdb3;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center; } }
.swiper-container {
  max-width: 910px;
  display: flex; }


// .swiper-wrapper
//   max-width: 910px
//   display: flex
//   justify-content: center



// div.swiper-slide
//   max-width: 270px

// .swiper-button-next

// .swiper-slide-prev

