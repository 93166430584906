.petList {
  &__title {
    width: 400px;
    text-align: center; }
  .container {
    display: flex;
    flex-direction: column; }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 100px; }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1170px; }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    width: 270px;
    height: 424px;
    border-radius: 9px;
    margin-top: 35px; }
  &__img {
    width: 270px;
    height: 270px;
    display: flex;
    align-items: flex-end;
    justify-content: center; }
  &__title--item {
    width: 74px;
    height: 24px;
    color: #545454;
    font-family: Georgia;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 25px;

    letter-spacing: 1.2px; }
  &__button {
    margin-bottom: 34px;
    border: 3px solid #f1cdb3;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center; } }
